import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import Seo from "../components/SEO";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
`;

const Header = styled.header`
  text-align: center;
  align-content: center;
`;

const Heading = styled.h1`
  font-size: 2rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  max-width: 80%;
`;

export default function Retreat({ data }) {
  const retreats = data.retreats.edges;
  return (
    <>
      <Seo title="Retreats" />
      <Layout>
        <Container>
          <Header>
            <Heading>Retreats</Heading>
          </Header>
          <RetreatList retreats={retreats} />
        </Container>
      </Layout>
    </>
  );
}

function RetreatList(retreats) {
  const list = retreats.retreats;
  return (
    <div>
      {list.map((retreat) => {
        return (
          <div key={retreat.node.id}>
            {/* <Img fluid={retreat.image.asset.fluid} alt={retreat.name} /> */}
            <Link to={`${retreat.node.slug.current}`}>
              <p>
                <span className="name-tile">{retreat.node.name}</span>
              </p>
            </Link>
          </div>
        );
      })}
    </div>
  );
}

export const query = graphql`
  query {
    retreats: allSanityService(
      filter: { serviceType: { name: { eq: "Retreats" } } }
    ) {
      edges {
        node {
          id
          name
          slug {
            current
          }
          # image {
          #   asset {
          #     gatsbyImageData(aspectRatio: 1.5)
          #   }
          # }
          # image {
          #   asset {
          #     fluid(maxWidth: 400) {
          #       ...GatsbySanityImageFluid
          #     }
          #   }
          # }
        }
      }
    }
  }
`;
